import React from 'react';

import './css/Header.css';

const HeaderBar = ({ headerSpacer }) => {
  return (
    <div class="Header-bar">
      <button class="Header-bar-button" onClick={() => document.location='/'}>Home</button>
      <button class="Header-bar-button" onClick={() => document.location='/writing'}>Writing</button>
      <button class="Header-bar-button" onClick={() => document.location='/projects'}>Projects</button>
    </div>
  );
}

export default HeaderBar;