export default [
  {
    title: 'Barbarian Days',
    author: 'William Finnegan',
    description: '',
    image: './book_images/BarbarianDays.jpg',
    finished: 2019,
  },
  {
    title: 'God\'s Debris',
    author: 'Scott Adams',
    description: '',
    image: './book_images/GodsDebris.png',
    finished: 2019,
  },
  {
    title: 'Atlas Shrugged',
    author: 'Ayn Rand',
    description: '',
    image: './book_images/AtlasShrugged.png',
    finished: 2019,
  },
  {
    title: 'Genome',
    author: 'Matt Ridley',
    description: '',
    image: './book_images/Genome.png',
    finished: 2019,
  },
  {
    title: 'Waking Up',
    author: 'Sam Harris',
    description: '',
    image: './book_images/WakingUp.png',
    finished: 2019,
  },
  {
    title: 'Sapiens',
    author: 'Yuval Noah Harari',
    description: '',
    image: './book_images/sapiens.png',
    finished: 2019,
  },
  {
    title: 'How to Change Your Mind',
    author: 'Michael Pollan',
    description: '',
    image: './book_images/HowToChangeYourMind.png',
    finished: undefined,
  },
  {
    title: 'Six Easy Pieces',
    author: 'Richard Feynman',
    description: '',
    image: './book_images/SixEasyPieces.png',
    finished: undefined,
  },
]