import React from 'react';
import Header from './Header';

import HeaderBar from './HeaderBar';

const SiteContainer = ({ children }) => {
    return (
        <div>
            <HeaderBar />
            {children}
        </div>
    );
};

export default SiteContainer;