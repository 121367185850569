import React from 'react';

import Title from '../components/Title';
import Preview from '../components/Preview';
import blogposts from '../assets/blogposts';

import './css/ScreenStyle.css'
import SiteContainer from '../components/SiteContainer';
import ContentContainer from '../components/ContentContainer';

const WritingScreen = () => {
  return (
    <SiteContainer>
      <ContentContainer>
        <Title>Writing</Title>
        {blogposts.map((blogpost) => {
          return <Preview
            image=""
            url={`${blogpost.url}`}
            title={`${blogpost.title}`}
            date={`${blogpost.date}`}
          />
        })}
      </ContentContainer>
    </SiteContainer>
  );
};

export default WritingScreen;