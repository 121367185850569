import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BlogPostScreen from '../screens/BlogPostScreen';
import Connect4 from '../screens/Connect4';
import HomeScreen from '../screens/HomeScreen';
import WritingScreen from '../screens/WritingScreen';
import ProjectsScreen from '../screens/ProjectsScreen';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={HomeScreen} />
      <Route path="/connect4" component={Connect4} />
      <Route path="/writing" exact component={WritingScreen} />
      <Route path="/writing/:url" component={BlogPostScreen} />
      <Route path="/projects" exact component={ProjectsScreen} />
      {/* <Route path="/dashboard" component={Dashboard} isPrivate /> */}
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={HomeScreen} />
    </Switch>
  );
}