import React from 'react';
import ContentContainer from '../components/ContentContainer';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SiteContainer from '../components/SiteContainer';
import Title from '../components/Title';
import projects from '../assets/projects';

import img from '../assets/project_images/ParkingEmail.png';
import imgs from '../assets/project_images';

const ProjectsScreen = () => {
    return(
        <SiteContainer>
            <ContentContainer>
                <Title>Projects</Title>
                {projects.map((proj) => {
                    return (
                        <p>
                        <strong style={{ fontSize: 20 }}>{proj.title}</strong>
                        <br />
                        {proj.html}
                        <br />
                        {/* <Row>
                            {proj.images.map((imgUrl) => {
                                console.log(imgs);
                                return (
                                    <Col>
                                    <Image
                                        // src={require(`../assets/project_images/${imgUrl}`)}
                                        src={imgs[imgUrl]}
                                        thumbnail
                                        style={{ width: '100%' }}
                                    />
                                    </Col>
                                );
                            })}
                        </Row> */}
                        </p>
                    );
                })}
            </ContentContainer>
        </SiteContainer>
    );
};

export default ProjectsScreen;
