import React from 'react';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import "./css/Preview.css";

const Preview = ({ image, title, width = "100%", height = "160px", url, date }) => {
  console.log(`../assets/${image}`);
  // return (
  //   <div class="Preview">
  //     <Card className="bg-dark text-white">
  //       <Card.Img src={require(`../assets/${image}`)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
  //       <Card.ImgOverlay style={{}}>
  //         <Card.Title>{title}</Card.Title>
  //         <Card.Text style={{ textAlign: 'right' }}>{date}</Card.Text>
  //       </Card.ImgOverlay>
  //     </Card>
  //   </div>
  // );

  return (
    <div
      class="Preview"
      onClick={() => document.location = `/writing/${url}`}
      style={{
        ...styles.containerStyle,
        width,
        height,
        backgroundColor: image ? 'transparent' : 'black',
      }}
    >
      {image ? <Image
        src={require(`../assets/${image}`)}
        rounded
        style={{ width, height }}
        class="PreviewImage"
      /> : null}
      <p class="PreviewTitle">{title}</p>
      <p class="PreviewDate">{date}</p>
    </div>
  );
};

const styles = {
  containerStyle: {
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: 24,
    margin: 10,
    borderRadius: 5,
  },
};

export default Preview;