import React from 'react';
import Container from 'react-bootstrap/Container';

import blogposts from '../assets/blogposts.js';
import ContentContainer from '../components/ContentContainer.js';
import SiteContainer from '../components/SiteContainer.js';

const BlogPostScreen = (props) => {
  return (
    <SiteContainer>
      <ContentContainer>
          {blogposts.map((blogpost) => {
            if (blogpost.url === props.match.params.url) {
              return <>
                <h3><strong>{blogpost.title}</strong></h3>
                <p>{blogpost.date}</p>
                {blogpost.html}
              </>;
            }
          })}
      </ContentContainer>
    </SiteContainer>
  );
};

export default BlogPostScreen;