import React from 'react';
import Container from 'react-bootstrap/Container';

const ContentContainer = ({ children }) => {
  return (
    <Container style={{ backgroundColor: 'transparent', textAlign: 'left', marginTop: 60 }}>
      {children}
    </Container>
  );
}

export default ContentContainer;