import React from 'react';

const Title = ({ children }) => {
  return (
    <div
      style={{
        fontSize: 36,
      }}
    >
      {children}
    </div>
  );
};

export default Title;