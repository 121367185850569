import React from 'react';

import './css/Header.css';

const Header = ({ headerImage }) => {
  return (
    <div className="Header">
      <div class="Header-text">Kam Bielawski</div>
    </div>
  )
};

export default Header;