import parkingEmail from './ParkingEmail.png';
import projectWebsite from './Project_Website.png';
import spiffyHomepage from './SpiffyHomepage.png';
import spiffyPortraitEdit from './SpiffyPortraitEdit.png';
import spiffyShareLink from './SpiffyShareLink.png';
import spiffyUserHome from './SpiffyUserHome.png';
import triangleParkingHomepage from './TriangleParkingHomepage.png';
import triangleparkingwebsite from './triangleparkingwebsite.png';

export default {
    'parkingEmail.png': parkingEmail,
    './Project_Website.png': projectWebsite,
    './SpiffyHomepage.png': spiffyHomepage,
    './SpiffyPortraitEdit.png': spiffyPortraitEdit,
    './SpiffyShareLink.png': spiffyShareLink,
    './SpiffyUserHome.png': spiffyUserHome,
    './TriangleParkingHomepage.png': triangleParkingHomepage,
    './triangleparkingwebsite.png': triangleparkingwebsite,
}