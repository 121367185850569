import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

/* Import images */
import parkingEmail from './project_images/ParkingEmail.png';
import projectWebsite from './project_images/Project_Website.png';
import spiffyHomepage from './project_images/SpiffyHomepage.png';
import spiffyPortraitEdit from './project_images/SpiffyPortraitEdit.png';
import spiffyShareLink from './project_images/SpiffyShareLink.png';
import spiffyUserHome from './project_images/SpiffyUserHome.png';
import triangleParkingHomepage from './project_images/TriangleParkingHomepage.png';
import triangleparkingwebsite from './project_images/triangleparkingwebsite.png';
import wormViz1 from './project_images/WormViz_1.png';
import wormViz2 from './project_images/WormViz_2.png';
import seizurePredictions from './project_images/seizure_predictions.png';
import electrodePlacement from './project_images/electrode_placement.jpeg';
import signalingChannels from './project_images/signaling_channel_phenotypes.png';
import hncaModelDiagram from './project_images/model_diagram.png';


const projects = [
    {
        title: "Hierarchical Neural Cellular Automata",
        images: [],
        html: <>
            • <a href={`${process.env.PUBLIC_URL}/pdfs/HNCA_Morphogenesis_GECCO24_final.pdf`} target="_blank" rel="noopener noreferrer">[PDF]</a> <br />
            • Developed a novel neural cellular automata model and evolved it to perform morphogenesis. <br />
            • Showed hierarchical 
            <Row>
                <Col> <Image src={hncaModelDiagram} style={{ width: '100%' }} /></Col>
                <Col> <Image src={signalingChannels} style={{ width: '100%' }} /></Col>
            </Row>
        </>
    },
    {
        title: "Seizure Prediction with Few Sensors",
        images: [],
        html: <>
            • <a href={`${process.env.PUBLIC_URL}/pdfs/DS2_Seizure_Prediction.pdf`} target="_blank" rel="noopener noreferrer">[PDF]</a> <br />
            • Investigated the predictive ability of various ML models to predict seizure onset from timeseries of a small number of EEG electrodes. <br />
            • 1D CNNs with various architectures trained on CHB-MIT dataset (46GB, 664 recordings, 22 patients, 2 second epochs). <br />
            <Row>
                <Col> <Image src={electrodePlacement} style={{ width: '50%' }} /></Col>
                <Col> <Image src={seizurePredictions} style={{ width: '100%' }} /></Col>
            </Row>
        </>
    },
    {
        title: "WormViz",
        images: [],
        html: <>
            • Built and deployed full-stack data visualization web application for utilization in Dr. Brian Ackley's biology lab at KU. Displays experimental data gathered by lab members.
            <br />
            • React front-end, NodeJS back-end containerized with Docker/Docker Compose and hosted on an AWS EC2 server.
            <br />
            • Currently viewable <a href="http://ec2-3-129-98-89.us-east-2.compute.amazonaws.com/">here</a>. Paste "WBGene00014454" in the search box to see an example.
            <Row>
                <Col> <Image src={wormViz2} style={{ width: '100%' }} /></Col>
                <Col> <Image src={wormViz1} style={{ width: '50%' }} /></Col>
            </Row>
        </>
    },
    {
        title: "Lifespan Machines",
        images: [],
        html: <>
            • Implemented Dr. Stroustrup et al's <a href="https://github.com/nstroustrup/lifespan">Lifespan Machine</a> as a project in Dr. Brian Ackley's biology lab at KU.
            <br />
            • Repurposed Epson v850 flatbed scanners for automation of lifespan studies for <i>C. elegans</i> nematode worms.
            <br />
            • Worked with University of Kansas IT to setup network storage and permanent server locations.
            <br />
        </>
    },
    // {
    //     title: "In Lieu of U",
    //     images: [],
    //     html: <>
    //         • A platform for me to share my learning during my semester off from college
    //         <br />
    //         • <a href="https://inlieuofu.com">inlieuofu.com</a>
    //         <br />
    //         • Source: <a href="https://github.com/kambielawski/in-lieu-of-u">https://github.com/kambielawski/in-lieu-of-u</a>
    //     </>
    // },
    {
        title: 'Spiffy Composites',
        images: ['SpiffyHomepage.png', 'SpiffyPortraitEdit.png', 'SpiffyShareLink.png', 'SpiffyUserHome.png'],
        html: <>
            • The Spiffy Composites web app is a novel low-cost alternative to
            the current options for Greek organizations to get their composite.
            The Spiffy app allows each individual of the organization to upload
            a photo from their own device, while we algorithmically assemble the
            individual photos into a cohesive composite.
            <br />
            • <a href="http://app.spiffycomposites.com">app.spiffycomposites.com</a>
            <br />
            • React Native front-end, Google Cloud functions/Firebase back-end
            <Row>
                <Col>
                <Image
                    src={spiffyHomepage}
                    thumbnail
                    style={{ width: '100%' }}
                />
                </Col>
                <Col style={{ justifyContent: 'center' }}>
                <Image src={spiffyPortraitEdit} thumbnail style={{ width: '100%' }} />
                </Col>
                <Col>
                <Image src={spiffyShareLink} thumbnail style={{ width: '100%' }} />
                </Col>
                <Col>
                <Image src={spiffyUserHome} thumbnail style={{ width: '100%' }} />
                </Col>
            </Row>
        </>
    },
    {
        title: 'Twitta Data Accumulata',
        images: [],
        html: <>
            • Built a Python app that pulls Tweets and cleans the text to provide
            useful training data to natural language models.
            <br />
            • Trained multiple character-level and word-level language models
            using this data engine to provide input.
            <br />
            • Source: <a href="https://github.com/kambielawski/twitta_data_accumulata">https://github.com/kambielawski/twitta_data_accumulata</a>
        </>
    },
    {
        title: 'Triangle Parking',
        images: ['TriangleParkingHomepage.png', 'triangleparkingwebsite.png', 'ParkingEmail.png'],
        html: <>
            • Set up a website & app that helps my fraternity redeem parking
            tickets for football games that were purchased online
            <br />
            • React Native front-end, Google Cloud functions/Firebase back-end
            <div style={{ height: '50px' }}>
            <Row style={{ height: '50px' }}>
            <Col> <Image src={triangleParkingHomepage} thumbnail style={{ width: '50%' }} /></Col>
            <Col> <Image src={triangleparkingwebsite} thumbnail style={{ width: '50%' }} /></Col>
            {/* <Col> <Image src={projectWebsite} thumbnail style={{ width: '100%' }} /></Col> */}
            </Row>
            </div>
        </>
    }
];

export default projects;