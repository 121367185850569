import React from 'react';
import ContentContainer from '../components/ContentContainer';
import { Row, Col, Image } from 'react-bootstrap';

import Header from '../components/Header';
import HeaderBar from '../components/HeaderBar';
import SiteContainer from '../components/SiteContainer';
import Title from '../components/Title';
import blogposts from '../assets/blogposts';
import Preview from '../components/Preview';

import fishAndScales from '../assets/essay_images/fish_and_scales.jpg';


const HomeScreen = () => {
  return (
    <SiteContainer>
      <Header />
      <ContentContainer>
        <Row>
          <Col>
                <Title>Welcome!</Title>
            <p>Source of this website <a href="https://github.com/kambielawski/kam-bielawski">here</a></p>
            <p>I'm currently at UVM doing my PhD in Complex Systems with Josh Bongard.</p>
            <p>I like to swing dance, play piano, and train jiu jitsu when I'm not doing PhD things.</p>
            <p><strong>Some Links</strong></p>
            <ul>
              <li><a href="https://artistgeneration.substack.com/">Substack</a></li>
              <li><a href="https://github.com/kambielawski">Github</a></li>
              <li><a href="https://twitter.com/kamtb28">Twitter</a></li>
            </ul>
            <p><strong>Contact Me</strong></p>
            <ul>
              <li>816-325-0961</li>
              <li>kamtb28@gmail.com</li>
            </ul>
          </Col>
          <Col> 
            <Image src={fishAndScales} style={{ width: '100%' }} />
          </Col>
        </Row>
        <hr />
        <p style={{ backgroundColor: 'black', fontSize: 24, color: 'white', paddingLeft: 10 }}><strong>Blog</strong></p>
        {
          blogposts.map((blogpost) => {
            return (<>
              <h3><strong>{blogpost.title}</strong></h3>
              <p>{blogpost.date}</p>
              {blogpost.html}
              <hr />
            </>);
          })
        }

      </ContentContainer>
    </SiteContainer>
  )
}

export default HomeScreen;